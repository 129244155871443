import React from "react"
import { StaticQuery, graphql } from "gatsby"

import FAQs from "../components/Pages/FAQs"

import "../styles/styles.css"

const query = graphql`
  query {
    headerImg: imageSharp(
      original: { src: { regex: "/.*dotted-kangaroo.*/" } }
    ) {
      id
      fixed(height: 700, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    accordeonImg: imageSharp(
      original: { src: { regex: "/.*dotted-kangaaroo-light.*/" } }
    ) {
      id
      fixed(height: 200, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/pages/faqs/" } }) {
      frontmatter {
        title
        subtitle
        faqs {
          title
          description
        }
      }
    }
  }
`

const FAQsPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      return (
        <FAQs
          {...props}
          page={data.page.frontmatter}
          headerImg={data.headerImg}
          accordeonImg={data.accordeonImg}
        />
      )
    }}
  />
)

export default FAQsPage
